// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { DiscussionEmbed } from 'disqus-react';

// Components
import Sidebar from './Sidebar';
import Loader from '../shared/Loader';
import Meta from '../shared/Meta';
import Ad from '../shared/Ad';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';
import blogPostActions from '../../actions/blogPostActions';

// Selectors
import { getBlogPost, getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import sidebarStyles from '../../../stylesheets/public/layouts/SidebarLayout.module.postcss';
import styles from './Blog.module.postcss';

class BlogPost extends Component {
  constructor(props) {
    super(props);
    const match = props.computedMatch || props.match;
    this.id = match.params.id;
    this.state = {
      ready: false || IsoMorphUtils.ready(props)
    };
  }

  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    } else {
      this.fetchBlogPost();
      this.setState({ ready: true });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const currentMatch = this.props.computedMatch || this.props.match;
    const nextMatch = nextProps.computedMatch || nextProps.match;

    if ((currentMatch.params && nextMatch.params) &&
        (currentMatch.params.id !== nextMatch.params.id)) {
      this.id = nextMatch.params.id;
      this.fetchBlogPost();
    }
  }

  fetchBlogPost() {
    this.props.fetchBlogPost(this.id);
  }

  render() {
    const { blogPost, location, cityConfig } = this.props;
    if (!this.state.ready || !blogPost) return null;

    const url = `${IsoMorphUtils.railsContext.href}/${blogPost.id}`;
    const photoUrl = `${IsoMorphUtils.railsContext.imageBase}/${blogPost.photo}`;

    const disqusConfig = {
      url: url,
      identifier: `sft-blog-${blogPost.id}`,
      title: blogPost.title,
    };

    return (
      <div className="PaddedSection BlogPostDetail">
        { blogPost &&
          <Meta
            title={`${blogPost.title} - ${cityConfig.site_title}`}
            structuredData={{
              blogPost: {
                url: url,
                headline: blogPost.title,
                image: photoUrl,
                datePublished: moment(blogPost.post_date).format(),
                dateModified: moment(blogPost.updated_at).format(),
              },
              breadcrumbs: { listItems: [
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Blog",
                    "item": cityConfig.base_url + "/blog",
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": blogPost.title,
                    "item": cityConfig.base_url + location.pathname,
                  }
                ]},
            }}
          />
        }
        <div className={sidebarStyles.SidebarLayout}>
          <div className="Container">
            <div className={sidebarStyles.SidebarLayout_body}>
              <Loader entities="blog" actions="fetch">
                {blogPost &&
                  <div>
                    <h1>{blogPost.title}</h1>

                    <h3>{moment(blogPost.post_date).format('LL')}</h3>

                    <div className={sidebarStyles.BlogPost_image}>
                      {blogPost.photo &&
                        <img
                          src={photoUrl}
                          alt={blogPost.title}
                        />
                      }
                    </div>

                    <div
                      className={styles.BlogPost_body + " u-mb3"}
                      dangerouslySetInnerHTML={{ __html: blogPost.body }}
                    />

                    <DiscussionEmbed
                      shortname={cityConfig.disqus_shortname}
                      config={disqusConfig}
                    />

                    {/* <div className="Advertisement u-mt2 u-mb1">
                      <Ad slot="7735243397" />
                    </div> */}
                  </div>
                }
              </Loader>
            </div>
            <div className={sidebarStyles.SidebarLayout_sidebar}>
              <Sidebar marginTop />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BlogPost.propTypes = {
  fetchBlogPost: PropTypes.func,
  clearBlogPosts: PropTypes.func,
  blogPost: PropTypes.object,
  match: PropTypes.object,
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  computedMatch: PropTypes.object,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    blogPost: getBlogPost(state, props),
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearBlogPosts() {
      return dispatch(blogPostActions.clear());
    },
    fetchBlogPost(id) {
      dispatch(blogPostActions.fetchOneIfNeeded(id));
    },
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlogPost));
